<template>
 <PDialog :header="'Editeur de consigne'" :modal="true" :visible.sync="display" :contentStyle="{overflow: 'visible'}" :containerStyle="{width: '40vw'}">
  <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
    <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()" >
      <div class="row">
        <!-- <div class="col-sm-12 md:col-12 field">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label for="libelle">Libellé du contenu</label>
            <div>
              <PInputText class="col-12 md:col-12" v-model="editableItem.libelle" />
            </div>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>  
          </ValidationProvider>
        </div> -->
        <div class="col-sm-12 field ">
          <div class="form-floating mb-3">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="libelle">Observation</label>
              <div>
                <PTextarea class="col-12 md:col-12" v-model="editableItem.observation" :autoResize="true" rows="8"/>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>  
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-12 field ">
          <div class="form-floating mb-3">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="libelle">Fichier</label>
              <div>
                <FilePondUploader ref="fileUploader" class="col-12 md:col-12" v-model="editableItem.observation" @save="fileSaved" :autoResize="true" rows="8"/>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>  
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-6 field">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div>Date de début</div>
              <p-calendar
                class="col-12 md:col-12"
                :showIcon="true"
                size="sm"
                :showTime="true"
                locale="fr"
                name="dateNais"
                :manualInput="false"
                v-model="editableItem.dateDebut"
                :showWeek="true"
                />  
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </ValidationProvider>
        </div>
        <div class="col-sm-6 field">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div>Date de fin</div>
              <p-calendar
                class="col-12 md:col-12"
                :showIcon="true"
                size="sm"
                :showTime="true"
                locale="fr"
                name="dateNais"
                :manualInput="false"
                v-model="editableItem.dateFin"
                :showWeek="true"
                />  
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </ValidationProvider>
        </div>
        <div class="form-floating mb-3">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="col-sm-12 field  md:col-12 field">
              <label for="libelle">Observation</label>
              <div class="col-12 md:col-12">
                <PDropdown
                  v-model="editableItem.etat"
                  class="col-12 md:col-12"
                  :options="etats"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Etat de la consigne">
                  <template #option="slotProps">
                    <span :class="'product-badge status-' + slotProps.option.value.toLowerCase()">{{slotProps.option.label}}</span>
                  </template>
                </PDropdown>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>  
            </div>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
  <template #footer>
    <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text"/>
    <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
  </template>
</PDialog>
</template>

<script>
import FilePondUploader from '../../../../uploader/FilePondUploader.vue'

export default {
  components: {
    FilePondUploader
  },
  props: {
    title: {
      type:  String,
      required: true
    },
    item: {
      type:  Object,
      default: () => ({})
    }
  },
  data () {
    return {
      display: false,
      editableItem: this.item,
    }
  },
  computed: {
    etats () {
      return [
        {
          value: 'NON_REALISE',
          label: 'Non Réalisé'
        },
        {
          value: 'EN_COURS',
          label: 'En cours'
        },
        {
          value: 'TERMINE',
          label: 'Terminé'
        }
      ]
    }
  },
  watch: {
    item(){
      this.editableItem = { ...this.item }
    }
  },
  methods: {
    show () {
      this.display = true
    },
    hide () {
      this.display = false;
    },
    fileSaved(files){
      console.log(files)
      this.editableItem = {
        ...this.editableItem,
        fileUrl: this.$refs.fileUploader.getFilesUrls()
      }
    },
    cancel () {
      this.hide()
      this.$emit('cancel')
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      this.$emit('save', this.editableItem)
      this.hide()
    },
  }
  
}
</script>